<template>
  <div>
    <div
      class="grid mt-3 flex"
      :class="cardsShown < 3 ? 'lg:justify-content-center' : ''"
    >
      <div class="col-12" :class="colLg">
        <div
          class="surface-card shadow-2 p-3 border-1 border-50 border-round grid mx-0 h-100"
        >
          <div class="col-12 lg:col-8">Response Funnel</div>
          <div class="mt-3 col-12 text-center" style="height: 100%">
            <FunnelChart
              :dataset="responseFunnelData"
              :options="{
                chart: {
                  height: '200',
                  width: '250',
                },
              }"
              :color-scheme="colorScheme"
            />
          </div>
        </div>
      </div>
      <div class="col-12" :class="colLg" v-if="deviceTypeShown">
        <div
          class="surface-card shadow-2 p-3 border-1 border-50 border-round grid mx-1 h-100"
        >
          <div class="col-12 lg:col-7">Device Type</div>
          <!-- <div class="col-12 lg:col-5 text-right">
            <Dropdown
              v-model="dataset"
              :options="[
                { n: 'Leads', v: 'web_leads' },
                { n: 'Visits', v: 'web_visits' },
              ]"
              option-value="v"
              option-label="n"
              placeholder="Dataset"
            />
          </div> -->
          <div class="col-12 mt-2">
            <Chart
              type="pie"
              ref="deviceChart"
              :data="deviceTypeChartData"
              :options="chartOptions"
              height="200px"
              width="200px"
            />
          </div>
        </div>
      </div>
      <div class="col-12" :class="colLg" v-if="sourceShown">
        <div
          class="surface-card shadow-2 p-3 border-1 border-50 border-round mx-0 grid h-100"
        >
          <div class="col-12 lg:col-7">Source</div>
          <!-- <div class="col-12 lg:col-5 text-right">
            <Dropdown
              v-model="dataset"
              :options="[
                { n: 'Leads', v: 'web_leads' },
                { n: 'Visits', v: 'web_visits' },
              ]"
              option-value="v"
              option-label="n"
              placeholder="Dataset"
            />
          </div> -->

          <div class="col-12 mt-2">
            <Chart
              type="pie"
              ref="deviceChart"
              :data="sourceChartData"
              :options="chartOptions"
              height="200px"
              width="200px"
            />
          </div>
        </div>
      </div>
      <div class="col-12" :class="colLg" v-if="siteVersionShown">
        <div
          class="surface-card shadow-2 p-3 border-1 border-50 border-round grid mx-0 h-100"
        >
          <div class="col-12 lg:col-7">Site Version</div>
          <!-- <div class="col-12 lg:col-5 text-right">
            <Dropdown
              v-model="dataset"
              :options="[
                { n: 'Leads', v: 'web_leads' },
                { n: 'Visits', v: 'web_visits' },
              ]"
              option-value="v"
              option-label="n"
              placeholder="Dataset"
            />
          </div> -->

          <div class="mt-2 col-12">
            <Chart
              type="pie"
              ref="deviceChart"
              :data="abChartData"
              plugin
              :options="chartOptions"
              height="200px"
              width="200px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FunnelChart from "@/components/FunnelChart";
import { useAppStore } from "@/stores/app";
export default {
  name: "CampaignOverview",
  components: { FunnelChart },
  props: {
    campaign: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
          },
        },
      },
      deviceTypeChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: this.colorScheme,
            hoverBackgroundColor: this.colorSchemeHover,
          },
        ],
      },
      sourceChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#4f6980", "#849db1"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"],
          },
        ],
      },
      abChartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#4f6980", "#849db1"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"],
          },
        ],
      },
      dataset: "web_leads", // web_leads, web_visits
    };
  },
  mounted() {
    this.setChartData(this.campaign);
  },
  watch: {
    campaign() {
      this.setChartData(this.campaign);
    },
    dataset() {
      this.setChartData(this.campaign);
    },
  },
  computed: {
    responseFunnelData() {
      if (!this.campaign) return [];

      let funnelData = [];

      funnelData.push({ label: "Mailed", value: this.campaign.mailed.total });
      if (this.campaign.delivered.total)
        funnelData.push({
          label: "Delivered",
          value: this.campaign.delivered.total,
        });
      funnelData.push({
        label: "Responded",
        value: this.campaign.web_visits.total,
      });
      funnelData.push({ label: "Leads", value: this.campaign.web_leads.total });

      return funnelData;
    },
    sourceShown() {
      return this.sourceChartData.labels.length > 1;
    },
    siteVersionShown() {
      return this.abChartData.labels.length > 1;
    },
    deviceTypeShown() {
      return this.deviceTypeChartData.labels.length > 1;
    },
    cardsShown() {
      return (
        this.sourceShown + this.siteVersionShown + this.deviceTypeShown + 1
      );
    },
    colLg() {
      switch (this.cardsShown) {
        case 2:
          return "lg:col-4";
        case 3:
          return "lg:col-4";
        default:
          return "lg:col-3";
      }
    },
    colorSchemeFromStore() {
      return this.appStore.colorScheme;
    },
    colorScheme() {
      if (this.colorSchemeFromStore) {
        return this.colorSchemeFromStore;
      } else {
        return ["#4f6980", "#afbc36", "#a2ceaa", "#638b66"]; // TODO: Return default color scheme
      }
    },
    colorSchemeHover() {
      let colorScheme = this.colorSchemeFromStore
        ? this.colorSchemeFromStore
        : [];
      return colorScheme.map((color) => this.adjustColorBrightness(color, -30));
    },
  },
  methods: {
    getMap(arr, key) {
      return arr.reduce(
        (entryMap, e) =>
          entryMap.set(e[key], [...(entryMap.get(e[key]) || []), e]),
        new Map()
      );
    },
    getLabelsAndValues(arr, key) {
      const grouped = this.getMap(arr, key);
      const labels = [];
      const values = [];

      grouped.forEach((value, key) => {
        if (key !== null) {
          labels.push(key.toUpperCase());
          values.push(value.reduce((a, b) => a + (b["agg"] || 0), 0));
        }
      });

      return {
        labels: labels,
        values: values,
      };
    },
    setChartData(campaignData) {
      let result;

      // device types
      result = this.getLabelsAndValues(
        campaignData[this.dataset].breakdown,
        "device_type"
      );
      this.deviceTypeChartData.labels = result.labels;
      this.deviceTypeChartData.datasets[0].data = result.values;
      this.deviceTypeChartData.datasets[0].backgroundColor = this.colorScheme;
      this.deviceTypeChartData.datasets[0].hoverBackgroundColor =
        this.colorSchemeHover;

      // Source data
      result = this.getLabelsAndValues(
        campaignData[this.dataset].breakdown,
        "initial_response_channel"
      );

      this.sourceChartData.labels = result.labels;
      this.sourceChartData.datasets[0].data = result.values;
      this.sourceChartData.datasets[0].backgroundColor = this.colorScheme;
      this.sourceChartData.datasets[0].hoverBackgroundColor =
        this.colorSchemeHover;

      // Set A/B test data
      result = this.getLabelsAndValues(
        campaignData[this.dataset].breakdown,
        "site_version"
      );

      this.abChartData.labels = result.labels;
      this.abChartData.datasets[0].data = result.values;
      this.abChartData.datasets[0].backgroundColor = this.colorScheme;
      this.abChartData.datasets[0].hoverBackgroundColor = this.colorSchemeHover;
    },
  },
};
</script>

<style>
.h-100 {
  height: 100%;
}
</style>
